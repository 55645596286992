export const GenericText = () => import('./../../components/content/GenericText.vue')
export const Grid = () => import('./../../components/content/Grid.vue')
export const GridItem = () => import('./../../components/content/GridItem.vue')
export const Hero = () => import('./../../components/content/Hero.vue')
export const HorizontallyScrollable = () => import('./../../components/content/HorizontallyScrollable.vue')
export const Indent = () => import('./../../components/content/Indent.vue')
export const MailShowcase = () => import('./../../components/content/MailShowcase.vue')
export const Scribble = () => import('./../../components/content/Scribble.vue')
export const Subscribe = () => import('./../../components/content/Subscribe.vue')
export const Warning = () => import('./../../components/content/Warning.vue')
export const globalComponents: string[] = ["ProseA","ProseBlockquote","ProseCode","ProseEm","ProseH1","ProseH2","ProseH3","ProseH4","ProseH5","ProseH6","ProseHr","ProseImg","ProseLi","ProseOl","ProseP","ProsePre","ProseScript","ProseStrong","ProseTable","ProseTbody","ProseTd","ProseTh","ProseThead","ProseTr","ProseUl"]
export const localComponents: string[] = ["GenericText","Grid","GridItem","Hero","HorizontallyScrollable","Indent","MailShowcase","Scribble","Subscribe","Warning"]