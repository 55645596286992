<script lang="ts" setup>
import type { UseHeadInput, MergeHead } from '@unhead/vue'

const route = useRoute()

const { data: page } = await useAsyncData(route.path, () => {
  return queryCollection('content').path(route.path).first()
})

useLocaleHead()
useHead((page.value?.head as UseHeadInput<MergeHead>) || {})
useSeoMeta(page.value?.seo || {})

defineOgImageComponent('NuxtSeo', {
  title: page.value?.title || 'Silvabird',
  theme: '#d83441',
  colorMode: 'dark',
  siteLogo: '/img/logos/logo-with-text.svg'
})

</script>

<template>
  <main>
    <ContentRenderer v-if="page" :value="page" />
  </main>
</template>
